/* src/components/Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111414;
  padding: 10px 5%;
  font-weight: bold;
}
.app-button {
  color: white;
  background-color: coral;
  border: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 1em;
  text-decoration: none;
  font-size: 0.75em;
  text-align: center;
}
.app-button:hover {
  color: white;
  background-color: lightcoral;
}

.navbar.light {
  background-color: #efefef;
}

.navbar-logo {
  padding-top: 10px;
}

.navbar-logo a {
  color: #efefef;
  font-size: 1.5rem;
  text-decoration: none;
}

.navbar.light .navbar-logo a {
  color: #111414;
}

.navbar-links {
  display: flex;
  gap: 25px;
  cursor: pointer;
}

.navbar-links a {
  position: relative;
  color: #efefef;
  text-decoration: none;
  font-size: small;
}

.navbar-links a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: currentColor;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.navbar-links a:hover {
  text-decoration: none; /* Add this to remove the default underline */
}

.navbar-links a:hover::before {
  visibility: visible;
  width: 100%;
}

.navbar.light .navbar-links a {
  color: #111414;
  font-size: small;
}

.navbar.light .navbar-links a:hover {
  text-decoration: none;
}

.navbar .navbar-links a:hover {
  text-decoration: none;
}

.navbar-buttons {
  display: flex;
  gap: 15px;
}

.navbar-buttons button {
  border: none;
  background: none;
  color: #efefef;
  cursor: pointer;
}

.navbar.light .navbar-buttons button {
  color: #111414;
}

.navbar-buttons button:focus {
  outline: none;
}

.navbar-buttons button:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: text-decoration 0.2s ease-in-out;
}
.navbar-links a:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: text-decoration 0.2s ease-in-out;
}
.material-icons {
  font-size: 1rem;
  vertical-align: middle;
}

/* Add this to src/components/Navbar.css */

.hamburger-menu {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/* Update this media query in src/components/Navbar.css */
@media (max-width: 1024px) {
  .navbar-links {
    display: none;
    position: absolute;
    top: 70px;
    flex-direction: column;
    gap: 25px;
    background-color: #111414;
    padding: 15px;
    border-radius: 0 0 0 10px;
    z-index: 1000;
    animation: fadeOutUp 0.3s ease-in-out forwards; /* Add this line */
  }
  .navbar-links.open {
    display: flex;
    left: 0%;
    animation: fadeInDown 0.3s ease-in-out forwards; /* Add this line */
  }

  .navbar-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .hamburger-menu {
    display: block;
  }
}

.navbar.light .navbar-links {
  background-color: #efefef;
}

/* Add these rules to change the hamburger button color according to the theme */
.hamburger-menu.light {
  color: #111414;
}

.hamburger-menu.dark {
  color: #efefef;
}
