/* src/components/WhatWeDo.css */

.whatwedo-section {
  position: relative;
  display: flex;
  min-height: 100vh;
  background-image: url(../img/background.jpg);
  background-size: 75%;
  background-position: 100%;
}
.whatwedo-section::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.whatwedo-section.light::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);
}

.whatwedo-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 0%;
}

.whatwedo-content {
  position: relative;
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 5%;
  background-color: #111414;
  color: #efefef;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  max-width: 100%;
}

.whatwedo-content.light {
  position: relative;
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 5%;
  background-color: #efefef;
  color: #111414;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
}

.whatwedo-content h1 {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.whatwedo-content div {
  max-width: 50%;
}

.whatwedo-content p {
  line-height: 1.5;
}
@media (max-width: 1024px) {
  .whatwedo-section {
    position: relative;
    display: flex;
    min-height: 100vh;
    background-image: url(../img/background.jpg);
    background-size: 190%;
    background-position: 100%;
  }
}

@media (max-width: 768px) {
  .whatwedo-content h1 {
    font-size: 3rem;
    padding: 0;
  }

  .whatwedo-content div {
    max-width: 100%;
  }

  .whatwedo-content p {
    font-size: 1.2rem;
    padding: 0;
  }
  .whatwedo-section {
    position: relative;
    display: flex;
    min-height: 100vh;
    background-image: none;
    background-size: cover;
    overflow: hidden;
  }
  .whatwedo-content.light {
    clip-path: inset(0 0 0 0);
    padding: 0px 15%;
  }
  .whatwedo-content {
    clip-path: inset(0 0 0 0);
    padding: 0px 15%;
  }
}

.cta-button {
  background-color: #efefef;
  color: #111414;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  max-width: 200px;
  width: 100%;
}

.cta-button:hover {
  background-color: #ddd;
}

/* Add animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-out {
  opacity: 0;
}
.desktop-only {
  display: inline;
}

.mobile-only {
  display: none;
}
