.solutions-section {
  position: relative;
  display: flex;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  color: white;
  overflow: hidden;
}
.solutions-section .light {
  color: #111414;
}
@media (max-width: 1024px) {
  .solutions-section {
    flex-direction: column;
    margin: 2em;
    font-size: 0.75rem;
  }
}
@media (max-width: 768px) {
  .solutions-section {
    flex-direction: column;
    margin: 2em;
    font-size: 1rem;
  }
}
@media (max-width: 320px) {
  .solutions-section {
    flex-direction: column;
    margin: 2em;
    font-size: 0.4rem;
  }
}
