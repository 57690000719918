/* src/components/Implementation.css */

.implementation-section {
  position: relative;
  background-color: #111414;
  color: #efefef;
  padding: 50px 5%;
}

.implementation-section.light {
  background-color: #efefef;
  color: #111414;
}

.implementation-content {
  position: relative;
  z-index: 2;
  width: 50%;
}

.implementation-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px
  );
  background-size: 30px 30px;
  z-index: 1;
}

.implementation-section.light::before {
  background-image: radial-gradient(rgba(0, 0, 0, 0.25) 1px, transparent 1px);
}

.implementation-content {
  position: relative;
  z-index: 2;
  width: 50%;
  text-align: left;
}

@media (max-width: 768px) {
  .implementation-content {
    font-size: 0.95rem;
    width: 90%;
    text-align: left;
    margin-left: auto; /* Add this line */
    margin-right: auto; /* Add this line */
  }
}

.implementation-content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.desktop-only {
  display: inline;
}

.mobile-only {
  display: none;
}

@media (max-width: 425px) {
  .implementation-content {
    font-size: 0.75rem;
    text-align: left;
    width: 85%;
    margin-left: auto; /* Add this line */
    margin-right: auto; /* Add this line */
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: inline;
  }

  .implementation-content h1 {
    font-size: 4rem;
  }
}

@media (max-width: 320px) {
  .implementation-content {
    font-size: 0.5em;
    width: 85%;
    text-align: left;
    margin-left: auto; /* Add this line */
    margin-right: auto; /* Add this line */
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: inline;
  }
  .implementation-content h1 {
    font-size: 2.5rem;
  }
}
