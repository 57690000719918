/* src/components/BackToTopButton.css */

.back-to-top-button {
  position: fixed;
  bottom: 150px;
  right: 20px;
  background-color: inherit;
  border: 5px solid;
  padding: 10px;
  cursor: pointer;
  z-index: 2000;
  transition: background-color 0.2s ease-in-out;
  font-size: 3em;
}

.back-to-top-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.back-to-top-button.light {
  color: #111414;
  border-color: #111414;
}

.back-to-top-button.dark {
  color: #efefef;
  border-color: #efefef;
}
