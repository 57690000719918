/* src/components/Implementation.css */

.problem-section {
  position: relative;
  background-color: #111414;
  color: #efefef;
  padding: 50px 5%;
}

.problem-section.light {
  background-color: #efefef;
  color: #111414;
}

.problem-content2 {
  display: flex;
  text-align: right;
  margin: 10px;
  padding: 20px;
  align-items: center;
}

.problem-content2 img {
  width: 50%;
}
.problem-content2 h1 {
  font-size: 5em;
}
.problem-content2 h2 {
  font-size: 5em;
}
@media (max-width: 1024px) {
  .problem-content2 {
    flex-direction: column-reverse;
    margin: 1em;
    font-size: 0.5rem;
    text-align: left;
  }
  .problem-content2 img {
    width: 100%;
  }
  .problem-content2 h1 {
    font-size: 8em;
  }
}

@media (max-width: 768px) {
  .problem-content2 {
    flex-direction: column-reverse;
    margin: 1em;
    font-size: 0.5rem;
    text-align: left;
  }
  .problem-content2 img {
    width: 100%;
  }
  .problem-content2 h1 {
    font-size: 6em;
  }
}
