/* src/components/TheTeam.css */

.theteam-section {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  min-height: 100vh;
  background-color: #111414;
}
.theteam-section.light {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  min-height: 100vh;
  background-color: #efefef;
}

.left-column {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-column h1 {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.left-column p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.right-column {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%; /* Add this line */
  justify-items: center; /* Add this line */
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Add this line */
  max-width: 120px; /* Add this line */
  text-align: center;
}

.team-member img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden; /* Add this line */
}

.light .rectangle {
  background-color: #111414;
}

.dark .rectangle {
  background-color: #efefef;
}

.light h1,
.light h2,
.light h3,
.light h4,
.light p {
  color: #111414;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark p {
  color: #efefef;
}
@media (max-width: 1024px) {
  .theteam-section {
    flex-direction: column;
    height: auto;
    text-align: center;
  }

  .left-column,
  .right-column {
    flex-basis: 100%;
  }

  .right-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .left-column h1 {
    margin-bottom: 3rem;
  }
}
@media (max-width: 320px) {
  .team-member p {
    font-size: 1em;
  }

  .team-member h3 {
    font-size: 1em;
  }
  .team-member h4 {
    font-size: 1em;
  }
  .theteam-section {
    font-size: 0.75em;
  }
}
